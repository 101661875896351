import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import styles from '../pages/index.module.css';
import Chip from '@mui/material/Chip';
import { CSSProperties } from 'react';

export type RemangaBookItem = {
    id: number
    dir: string
    main_name: string
    rus_name: string
    en_name: string
    secondary_name: string
    type: string
    admin_rating: string
    avg_rating: string
    count_chapters: number
    cover_high: string
    cover_mid: string
    cover_low: string
    img: {
      high: string
      mid: string
      low: string
    }
}

export default function BookItem (props: {
    title: string
    img: string
    type?: string
    rating?: string
    url: string
    badge?: {
        code: string
        label: string
        icon: any
        color: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
    }
}) {
    return <Paper component={'a'} href={props.url} className={styles.bookItem} elevation={0} title={`Скачать ${props.type} ${props.title} бесплатно`}>
        { props.badge ? <div className={styles.badge}><Chip label={props.badge.label} color={props.badge.color} /></div> : <></> }
        <div className={styles.posterBlock}>
            <img
                className={styles.poster + ' poster'}
                src={props.img + '?size=bookItemPoster'}
                loading='lazy'
                alt={props.type + ' обложка ' + props.title}
            />
        </div>
        {
            props.type || props.rating ?
                <Typography variant="caption" display="block" gutterBottom>
                    {props.type} {props.rating}⭐
                </Typography>
            : <></>
        }
        <Typography variant="subtitle2" gutterBottom style={{ height: '70px', overflow: 'hidden' }}>
            <b>{props.title}</b>
        </Typography>
    </Paper>;
}

BookItem.Box = function (props: { title?: string, style?: CSSProperties, children: any, leftImage?: string }) {
    return (<Box style={{ ...(props.style ?? {}), width: 'inherit', position: 'relative' }}>
        {
            props.leftImage ?
                <div style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    backgroundImage: `url(${props.leftImage})`,
                    backgroundPosition: 'top left',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    left: '-48px'
                }}></div>
            : <></>
        }
        {
            props.title ? 
                <Typography variant="h6" display="block" gutterBottom style={{ color: 'orangered', position: 'relative' }}>
                    {props.title}
                </Typography>
            : ''
        }
        <Box className={styles.bookshelf} style={{ ...(props.leftImage ? {
            paddingLeft: '90px'
        } : {}) }}>
            <BookItem.HeadLine>
                {props.children}
            </BookItem.HeadLine>
        </Box>
    </Box>);
}

BookItem.HeadLine = function HeadLine (props: { children: any }) {
    return (<Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
        style={{ width: 'max-content' }}
    >{props.children}</Stack>)
}