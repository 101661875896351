import { useEffect } from "react";
import { shuffleArray } from "./utils";
import { useProfile } from "./client_side/profile";

export default function PostersBg () {
  const profile = useProfile();
  
  useEffect(() => {
    if (typeof profile.isMobile === 'undefined' || profile.isMobile === true) return;
    if ((globalThis.window.navigator as any).userAgentData?.mobile && globalThis.window.navigator.maxTouchPoints) return;

    const height = 210;
    const width = 150;

    const images = document.querySelectorAll('img.poster') as NodeListOf<HTMLImageElement>;
    if (!images.length) return;

    const validImages: HTMLImageElement[] = [];
    for (let i = 0; i < images.length; i++) {
      const img = images[i];
      if (!img.naturalWidth) continue;
      validImages.push(img);
      if (validImages.length === 16) break; // matrix 4x4
    }

    let updTo: NodeJS.Timeout;
    function handleImgLoader(ev: Event) {
      if (validImages.length === 16) return;
      if (ev.target) validImages.push(ev.target as HTMLImageElement);
      if (updTo) clearTimeout(updTo);
      updTo = setTimeout(gen, 1_000);
    }

    images.forEach(el => {
      if (!el.complete) {
        el.addEventListener('load', handleImgLoader);
      }
    });

    async function gen() {
      if (!validImages.length) return;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context!.globalAlpha = 0.5;
      context!.globalCompositeOperation = 'source-over';
      shuffleArray(validImages);

      // Рассчитываем размер стороны квадратной матрицы
      const n = Math.ceil(Math.sqrt(validImages.length));
      const matrix: HTMLImageElement[][] = [];

      let matrixFilled = false;
      for (let i = 0; i < n; i++) {
        matrix.push([]);
        for (let j = 0; j < n; j++) {
          // Индекс в исходном массиве, который нужно использовать для текущего элемента матрицы
          const index = (i * n + j) % validImages.length;
          if (index === validImages.length - 1) matrixFilled = true;
          matrix[i].push(validImages[index]);
        }
        if (matrixFilled) break;
      }

      canvas.height = height * matrix.length;
      canvas.width = width * (matrix[0]?.length ?? 0);

      if (!canvas.height || !canvas.width) return;

      for (let i = 0; i < n; i++) {
        for (let j = 0; j < n; j++) {
          try {
            context!.drawImage(matrix[i][j], j * width, i * height, width, height);
          } catch (e) {
            console.error(e);
          }
        }
      }
      
      const base64String = canvas.toDataURL();

      document.documentElement.style.setProperty('--background-url', `url(${base64String})`);
      document.documentElement.style.setProperty('--background-size', Math.round(5 * n) + '%');
    }

    gen();

    return () => {
      images.forEach(el => el.removeEventListener('load', handleImgLoader));
    }
  }, [profile.isMobile])

  return <></>;
}